<template>
  <div>
    <a-modal
        v-model="visibleModal"
        :footer="null"
        :maskClosable="false"
        :closable="false"
        :destroyOnClose="false"
        wrapClassName="modal-register"
    >
      <p class="register-title mb-0">{{ methodOTPSTT === 0 ? $t('Security.Register') : $t('Security.Cancel') }} {{ $t('Security.' + methodOTP) }}</p>
      <hr>
      <div v-if="methodOTPSTT === 0" class="register-body">
        <div class="w-100" v-if="methodOTP !== 'GoogleAuthenticator'">
          <div class="text-center fw-bold mb-3">
            <span v-if="methodOTP === 'SMS'">{{ $t('Security.PhoneNumber')}} <span class="text-danger">*</span></span>
            <span v-else-if="methodOTP === 'Email'">{{ $t('Security.EmailReceiveOTP')}} <span class="text-danger">*</span></span>
          </div>
          <a-input-group compact style="width: 100%">
            <a-input style="width: 70%;" v-if="methodOTP === 'SMS'" :placeholder="$t('Security.PlaceHolderPhone')" :disabled="disableBtnGetOTP"></a-input>
            <a-input style="width: 70%;" v-else-if="methodOTP === 'Email'" :placeholder="$t('Security.PlaceHolderMail')" :disabled="disableBtnGetOTP"></a-input>
            <a-button  style=" width: 30% ; color: #0a58ca" @click="getOTP(methodOTP)" :disabled="disableBtnGetOTP">{{ $t('Security.GetOTP') }} ({{timeOutOTP[methodOTP]}}s)</a-button>
          </a-input-group>
          <div class="text-center fw-bold mt-3 mb-0">{{ $t('Security.AuthenticationCode') }} <span class="text-danger">*</span></div>
          <div class="form-otp d-flex justify-content-center">
            <div class="ap-otp-inputs d-flex justify-content-center align-items-center">
              <label v-for="item in otpNumber" :key="item.key" class="inp-otp">
                <input
                    v-model="otp[item.key]"
                    @input="handleInput(item.key,$event)"
                    @paste="handlePaste(item.key)"
                    @keydown="handleKeydown(item.key,$event)"
                    class="ap-otp-input"
                    type="tel"
                    maxlength="1"
                    :data-index="item.key"
                    ref="otpInput"
                >
              </label>
            </div>
          </div>
          <p class="text-center method-description" v-if="methodOTP === 'SMS'">{{ $t('Security.SMSNoteOTP')}}</p>
          <p class="text-center method-description" v-else-if="methodOTP === 'Email'">{{ $t('Security.EmailNoteOTP')}}</p>
        </div>
        <div v-else>
          <div class="text-center fw-bold mb-3">
            <span v-if="methodOTP === 'SMS'">{{ $t('Security.PhoneNumber')}} <span class="text-danger">*</span></span>
          </div>
          <div class="text-center fw-bold mt-3 mb-0">{{ $t('Security.AuthenticationCode') }} <span class="text-danger">*</span></div>
          <div class="form-otp d-flex justify-content-center">
            <div class="ap-otp-inputs d-flex justify-content-center align-items-center">
              <label v-for="item in otpNumber" :key="item.key" class="inp-otp">
                <input
                    v-model="otp[item.key]"
                    @input="handleInput(item.key, $event)"
                    @paste="handlePaste(item.key)"
                    @keydown="handleKeydown(item.key,$event)"
                    class="ap-otp-input"
                    type="tel"
                    maxlength="1"
                    :data-index="item.key"
                    ref="otpInput">
              </label>
            </div>
          </div>
          <p class="text-center method-description">{{ $t('Security.GoogleAuthenticatorNoteOTP')}}</p>
        </div>
        <div class="text-center d-flex justify-content-center">
          <a-button type="primary" style="margin-right: 15px" @click="handleRegister" :disabled="disableBtn">{{ $t('Security.Register') }}</a-button>
          <a-button @click="closeModal">{{ $t('Security.Close') }}</a-button>
        </div>
      </div>
      <div v-else class="register-body">
        <p v-if="methodOTP === 'SMS'" class="cancel-title">{{ $t('Security.CancelTitle') }} {{ $t('Security.PhoneNumber') }} <span>*******888</span> {{ $t('Security.ToUnsubscribe')}}</p>
        <p v-else-if="methodOTP === 'Email'" class="cancel-title">{{ $t('Security.CancelTitle') }} email <span>l*******hoang@gmail.com</span> {{ $t('Security.ToUnsubscribe')}}</p>
        <p v-else class="cancel-title">{{ $t('Security.CancelTitleGGAuthen') }} <span>Google Authenticator</span></p>
        <div class="text-center">
          <a-button class="btn-get-otp-to-cancel" v-if="methodOTP !== 'GoogleAuthenticator'" @click="getOTP(methodOTP)" :disabled="disableBtnGetOTP">{{ $t('Security.GetOTP') }} ({{timeOutOTP[methodOTP]}}s)</a-button>
        </div>
        <div class="text-center fw-bold mt-0" style="margin-bottom: 10px">{{ $t('Security.AuthenticationCode') }} <span class="text-danger">*</span></div>
        <div class="form-otp d-flex justify-content-center" style="margin-bottom: 10px">
          <div class="ap-otp-inputs d-flex justify-content-center align-items-center">
            <label v-for="item in otpNumber" :key="item.key" class="inp-otp">
              <input
                  v-model="otp[item.key]"
                  @input="handleInput(item.key,$event)"
                  @paste="handlePaste(item.key)"
                  @keydown="handleKeydown(item.key,$event)"
                  class="ap-otp-input"
                  type="tel"
                  maxlength="1"
                  :data-index="item.key"
                  ref="otpInput"
              >
            </label>
          </div>
        </div>
        <div class="text-center d-flex justify-content-center">
          <a-button type="danger" style="margin-right: 15px" @click="handleCancel" :disabled="disableBtn">{{ $t('Security.Cancel') }}</a-button>
          <a-button @click="closeModal">{{ $t('Security.Close') }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import helper from "@/until/helper";

export default {
  name: "RegisterOTP",
  props: {
    visibleModal:{
      type: Boolean,
      default: false
    },
    methodOTP:{
      type: String,
      default: ''
    },
    methodOTPSTT:{
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      code: null,
      timeOutOTP: {
        'SMS': 60,
        'Email': 60
      },
      disableBtnByMethod: {
        'GoogleAuthenticator': false,
        'SMS': false,
        'Email': false
      },
      timerIdByMethod: {
        'GoogleAuthenticator': null,
        'SMS': null,
        'Email': null
      },
      timerId: null,
      disableBtnGetOTP: false,
      otpNumber: [{ key: 0 }, { key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }],
      otp: {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: ''
      },
      methodOtp : [
        {
          value: 1,
          label: 'Google Authentication',
        },
        {
          value: 2,
          label: 'OTP SMS',
        },
        {
          value: 3,
          label: 'OTP Email',
        },
      ],
      disableBtn: true,
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    isset: helper.isset,
    closeModal() {
      this.resetLocalData()
      this.$emit('close-modal');
    },
    resetLocalData() {
      // this.stopTimer()
      this.otp = {0: '', 1: '', 2: '', 3: '', 4: '', 5: ''}
    },
    countDownTimer (method) {
      if (this.timeOutOTP[method] > 0) {
        this.disableBtnByMethod[method] = true;
        this.timerId = setTimeout(() => {
          this.timeOutOTP[method] -= 1
          this.countDownTimer(method)
        }, 1000)
      }else{
        this.timeOutOTP[method] = 60;
        this.disableBtnByMethod[method] = false;
      }
    },
    stopTimer() {
      // if (this.timerId) {
      //   clearTimeout(this.timerId);
      //   this.timerId = null;
      // }
      // this.timeOut = 60;
      // this.disableBtnGetOTP = false;
    },
    getOTP(method){
      this.countDownTimer(method)
    },
    handlePaste(index) {
      const clip = event.clipboardData.getData('text').trim();
      if (!/\d{6}/.test(clip)) {
        event.preventDefault();
        return;
      }
      const otpArray = clip.split('');
      otpArray.forEach((char, i) => {
        if (this.otp[index + i] !== undefined) {
          this.otp[index + i] = char;
        }
      });
    },
    handleInput(key, event) {
      const value = event.target.value;
      if (value !== '' && key < this.otpNumber.length - 1) {
        this.otp[key] = value;
        this.$refs.otpInput[key + 1].focus();
      }

      this.disableBtn = !Object.keys(this.otp).every(key => this.otp[key] !== '');
    },
    handleKeydown(key, event) {
      if (event.key === 'Backspace') {
        if (this.otp[key] !== '') {
          this.otp[key] = '';
        } else if (key > 0) {
          this.$refs.otpInput[key - 1].focus();
          this.otp[key - 1] = '';
        }
      }
      this.disableBtn = !Object.keys(this.otp).every(key => this.otp[key] !== '');
    },
    handleRegister(){
      // call api
      let updateData = {
        method: this.methodOTP,
        status: 1
      }
      this.resetLocalData()
      this.$emit('update-method-stt', updateData);
    },
    handleCancel(){
      let updateData = {
        method: this.methodOTP,
        status: 0
      }
      this.resetLocalData()
      this.$emit('update-method-stt', updateData);
    }
  }
}
</script>

<style>
.modal-register .ant-modal-body{
  padding: 10px 0;
}
hr{
  margin-top: 10px;
}
.register-body{
  padding: 0 20px;
}
.register-title{
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #262626;
  text-align: center;
  margin-bottom: 24px;
}
.form-otp .ap-otp-inputs .ap-otp-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px;
  height: 34px;
  margin: 4px;
  text-align: center;
  font-size: 18px;
  background: #ffffff;
  border-radius: 4px;
  font-family: inherit;
  transition: all 0.15s ease;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #25282b;
  border: 1px silver solid;
}
.method-description{
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #B9B9B9;
}
.cancel-title{
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  text-align: center;
  margin-bottom: 0;
}
.cancel-title span{
  font-weight: 700;
}
.btn-get-otp-to-cancel{
  color: #1589F7 !important;
  border: none !important;
  margin: 10px 0;
}
div[aria-hidden="true"][tabindex="0"] {
  display: none !important;
}

</style>
