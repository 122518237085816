<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
    </PageTitle>
    <section class="section transaction-detail">
      <a-card class="mb-30 shadow">
        <h2>{{ $t('VirtualAccount.Heading')}}</h2>
        <p>{{ $t('VirtualAccount.Content')}}</p>
        <span style="color: #012970;font-weight: bold">{{ $t("VirtualAccountUsd.Bank") }} :</span>
        <br>
        <span class="mt-2 mb-3 d-flex">Vietnam Technological and Commercial Joint Stock Bank (Techcombank)</span>
        <span style="color: #012970;font-weight: bold">{{ $t("VirtualAccountUsd.Branch") }} :</span>
        <br>
        <span class="mt-2 mb-3 d-flex">Dong Do</span>
        <div class="row">
          <div class="col-12 col-md-4 d-flex justify-content-between">
            <div><span style="color: #012970;font-weight: bold">{{ $t("VirtualAccountUsd.SwiftCode") }} :</span>
              <span class="mt-2 mb-3 d-flex">VTCBVNVX</span></div>
            <div>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'20px' }" @click="copy('VTCBVNVX')" />
              </a-col>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 d-flex justify-content-between">
            <div><span style="color: #012970;font-weight: bold">{{ $t("VirtualAccountUsd.AccountNumber") }} :</span>
              <span class="mt-2 mb-3 d-flex">19035002190019</span></div>
            <div>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'20px' }" @click="copy('19035002190019')" />
              </a-col>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 d-flex justify-content-between">
            <div><span style="color: #012970;font-weight: bold">{{ $t("VirtualAccountUsd.AccountName") }} :</span>
              <span class="mt-2 mb-3 d-flex">CONG TY CO PHAN 9PAY</span></div>
            <div>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'20px' }" @click="copy('CONG TY CO PHAN 9PAY')" />
              </a-col>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 d-flex justify-content-between">
            <div><span style="color: #012970;font-weight: bold">{{ $t("VirtualAccountUsd.ContentNote") }} :</span>
              <span class="mt-2 mb-3 d-flex">PG {{ this.getProfileMerchant.short_name }} {{$t("VirtualAccountUsd.ContentNoteSend")}}</span></div>
            <div>
              <a-col :span="4" class="pl-30" align="center">
                <a-icon type="copy" :style="{ fontSize:'20px' }" @click="copy('PG ' + getProfileMerchant.short_name + ' '+ $t('VirtualAccountUsd.ContentNoteSend'))" />
              </a-col>
            </div>
          </div>
        </div>
      </a-card>
    </section>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import {mapGetters} from "vuex";
export default {
name: "AccountBalanceUsd",
  components: {
    AppMasterVue,
    PageTitle,
  },
  data() {
    return {
      title: 'Sidebar.MerchantUsdAccount',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.MerchantUsdAccount',
          routeName: 'MerchantUsdAccount',
          active: true,
        },
      ],
    }
  },
  mounted() {
    console.log(this.user)
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      getProfileMerchant: 'authentications/getProfileMerchant',
    }),
  },
  methods:{
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    copy(content) {
      navigator.clipboard.writeText(content);
      this.showNotice(
          'success',
          this.$t('Notice.Title.Confirm'),
          this.$t('Notice.Message.CopySuccess')
      );
    },
  }
}
</script>

<style scoped>

</style>