<template>
  <div>
    <a-modal v-model="visible" @cancel="handleCancel" :footer="null">
      <p class="text-center fw-bold f-20">{{$t('opt.confirm')}} </p>
      <hr>
      <div style="width: 100%">
        <div class="text-center fw-bold mb-3"> {{ $t('opt.method') }}  (<span class="text-danger">*</span>)</div>
        <a-input-group compact style="width: 100%">
          <a-select style="width: 70%;" @change="handleChange"  :placeholder="$t('opt.method')">
            <a-select-option :value="item.value"  v-for="(item, index) in methodOtp" :key="index">{{item.label}}</a-select-option>
          </a-select>
          <a-button @click="getOtp(selectedItem)" style=" width: 30% ; color: #0a58ca">Lấy mã ( {{ timeOut}}s )</a-button>
        </a-input-group>
        <div class="text-center fw-bold mt-3 mb-4"> {{ $t('opt.authenticationCode') }}  (<span class="text-danger">*</span>)</div>
        <div class="form-otp d-flex justify-content-center mb-3">
          <div class="ap-otp-inputs d-flex justify-content-center align-items-center">
            <label v-for="item in otpNumber" :key="item.key" class="inp-otp">
              <input
                  v-model="otp[item.key]"
                  @input="handleInput(item.key,$event)"
                  @paste="handlePaste(item.key)"
                  @keydown="handleKeydown(item.key,$event)"
                  class="ap-otp-input"
                  type="tel"
                  maxlength="1"
                  :data-index="item.key"
                  ref="otpInput"
              >            </label>
          </div>
        </div>
      </div>
      <div class="text-center d-flex justify-content-center">
        <a-button @click="confirmOtp()" :disabled="!isSubmitVisible" type="danger" style="margin-right: 15px">
          {{$t('opt.submit')}}
        </a-button>
        <a-button @click="handleCancel">{{$t('opt.cancel')}}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
name: "OtpForm",
  props: ['hiddenFormOtp'],
  data() {
    return {
      code: null,
      visible: false,
      timeOut: 60,
      otpNumber: [{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }, { key: 6 }],
      otp: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
      },
      disabledSubmit: true,
      selectedItem: null,
      countNumberInputOtp : 6,
      methodOtp : [
        {
          value: 1,
          label: 'Google Authentication',
        },
        {
          value: 2,
          label: 'OTP SMS',
        },
        {
          value: 3,
          label: 'OTP Email',
        },
      ]
    };
  },
  watch: {
    'hiddenFormOtp': function() {
      this.otp =  {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
      },
      this.visible = false
    },

  },
  computed: {
    isSubmitVisible() {
      return Object.values(this.otp).join('').length === 6;
    }
  },
  created() {
    this.fetchCode();
  },
  methods: {
    handleChange(value){
      this.timeOut = 60
      this.selectedItem = value
    },
    fetchCode() {
      // Thực hiện gọi API ở đây,

    },
    confirmOtp(){
      this.visible = false
      this.code = Object.values(this.otp).join('');
      this.$emit('submit', this.code);
    },
    getOtp(){
      if (this.selectedItem){
        const countdownInterval = setInterval(() => {
          if (this.timeOut > 0) {
            this.timeOut--;
          } else {
            clearInterval(countdownInterval);
          }
        }, 1000);
      }
    },
    openModal() {
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    handlePaste(index) {

        const clip = event.clipboardData.getData('text').trim();
        if (!/\d{6}/.test(clip)) {
          event.preventDefault();
          return;
        }
        const otpArray = clip.split('');
        otpArray.forEach((char, i) => {
          if (this.otp[index + i] !== undefined) {
            this.otp[index + i] = char;
          }
        });
    },
    handleInput(key,event) {
      const index = key - 1;
      if (event.target.value.length === 1 && index < this.otpNumber.length - 1) {
        this.$refs.otpInput[index + 1].focus();
      }
    },
    handleKeydown(key, event) {
      const index = key - 1;
      if (!event.target.value && event.key === 'Backspace' && index > 0 && this.otp[index] !== null) {
        this.$refs.otpInput[index - 1].focus();
      }
    },
  }
}
</script>

<style scoped>
.form-otp .ap-otp-inputs .ap-otp-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  width: 30px;
  height: 34px;
  margin: 4px;
  text-align: center;
  font-size: 18px;
  background: #ffffff;
  border-radius: 4px;
  font-family: inherit;
  transition: all 0.15s ease;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #25282b;
  border: 1px silver solid;
}
.form-otp .ap-otp-inputs .ap-otp-input {
  width: 50px !important;
  height: 56px !important;
}
</style>