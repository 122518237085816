<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>

    <section class="section security-section mb-0">
      <a-card class="mb-30 shadow" style="min-height: 70vh;">
        <h1>{{ $t('Security.Title') }}</h1>
        <div class="mb-4">
          <div class="method-otp" v-for="(method, index) in methodOTP" :key="index">
            <a-row>
              <a-col class="mb-3" :xs="{ span: 24 }" :xl="{ span: 12 }">
                <div class="method-title mb-2">
                  <h3>{{ $t('Security.' + method.title) }}</h3>
                  <div v-if="method.status === 0" class="method-status">
                    <p>{{ $t('Security.NotRegistered') }}</p>
                  </div>
                  <div v-else class="method-status status-active">
                    <p>{{ $t('Security.Registered') }}</p>
                  </div>
                </div>
                <p style="font-style: italic;">{{ $t('Security.' + method.description) }}</p>
                <p v-if="method.method === 1">{{ $t('Security.RegisteredPhoneNumber') }}: <span class="fw-bold">*******888</span></p>
                <p v-else-if="method.method === 2">{{ $t('Security.RegisteredEmail') }}: <span class="fw-bold">l*******hoang@gmail.com</span></p>
              </a-col>
              <a-col :xs="{ span: 24 }" :xl="{ span: 3 }">
                <a-button v-if="method.status === 1" class="method-button" type="danger" @click="showModalRegister(method.title, method.status)">{{ $t('Security.CancelBtn') }}</a-button>
                <a-button v-else class="method-button" type="primary" @click="showModalRegister(method.title)">{{ $t('Security.RegisterBtn') }}</a-button>
              </a-col>
            </a-row>
          </div>
        </div>
        <h1 class="mb-1">{{ $t('Security.LoginSectionTitle') }}</h1>
        <p>{{ $t('Security.LoginSectionDescription') }}</p>
        <toggle-button :value="loginSecurityStatus === 1 ? true: false" color="#82C7EB" :sync="loginSecurityStatus === 1 ? true: false" @change="changeStatus($event)"/>
      </a-card>
    </section>
    <RegisterOTP
        :methodOTP="dataRegister.method"
        :methodOTPSTT="dataRegister.status"
        :visibleModal.sync="visibleModalRegister"
        @close-modal="handleModalRegisterClose"
        @update-method-stt="handleRegisterOTP"
    ></RegisterOTP>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import RegisterOTP from "@/components/pages/Security/RegisterOTP";

export default {
  name: 'AccountSecurity',
  components: {
    AppMasterVue,
    PageTitle,
    RegisterOTP
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
    }),
  },
  data() {
    return {
      title: "Sidebar.AccountSecurity",
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.AccountSecurity",
          routeName: 'AccountSecurity',
          active: true,
        },
      ],
      methodOTP: [
        {
          'title': 'GoogleAuthenticator',
          'status': 1,
          'description': 'GoogleAuthenticatorDescription',
          'method': 0
        },
        {
          'title': 'SMS',
          'status': 0,
          'description': 'SMSDescription',
          'method': 1
        },
        {
          'title': 'Email',
          'status': 1,
          'description': 'EmailDescription',
          'method': 2
        }
      ],
      loginSecurityStatus: 0,
      visibleModalRegister: false,
      dataRegister: {
        method: '',
        status: 0
      },
    }
  },
  mounted() {},
  methods: {
    showModalRegister(method, status) {
      this.dataRegister.method = method
      this.dataRegister.status = status
      this.visibleModalRegister = true;
    },
    handleModalRegisterClose(){
        this.visibleModalRegister = false;
    },
    handleRegisterOTP(newData){
      if (Object.keys(newData).length > 0){
        this.methodOTP.filter(function(method){
          if (method.title === newData.method){
            method.status = newData.status
          }
        })
      }
      this.visibleModalRegister = false;
    },
    changeStatus(event){
      if (this.loginSecurityStatus === 1){
        alert('dmm')
      }
      this.loginSecurityStatus = event.value;
    },
  }
}
</script>

<style>
  .security-section h1{
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 27px;
    font-weight: 600;
    color: #012970;
  }
  .security-section p{
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #000000;
    font-style: italic;
  }
  .method-otp h3{
    font-weight: 700;
    font-size: 13px;
    line-height: 19.5px;
    color: #000000;
    margin-bottom: 0;
  }
  .method-otp p{
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #000000;
    margin-bottom: 5px;
    font-style: normal;
  }
  .method-otp .method-title{
    display: flex;
    align-items: center;
  }
  .method-otp .method-status{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #A0A4A8;
    width: 130px;
    height: 24px;
    margin-left: 20px;
    border-radius: 8px;
  }
  .method-otp .method-status p{
    font-weight: 700;
    font-size: 13px;
    line-height: 19.5px;
    color: #FFFFFF;
    margin-bottom: 0;
  }
  .status-active{
    background: #00C851 !important;
  }
  .method-otp .method-button{
    font-weight: 600;
    font-size: 13px;
    line-height: 19.5px;
    color: #FFFFFF;
    width: 100%;
    height: 30px;
  }
  @media (max-width: 1200px) {
    .method-otp .method-title{
      justify-content: space-between;
    }
    .method-otp{
      border-bottom: 1px solid #B9B9B9;
      margin-bottom: 20px;
    }
    .method-otp .ant-row{
      padding-bottom: 20px;
    }
  }
</style>